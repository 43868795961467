<template>
	<b-row>

		<b-col cols="12">
			<campaign-detail-analytics v-if="campaignData && campaignData.analytics.length >0" :analytics="campaignData.analytics"></campaign-detail-analytics>
		</b-col>
		<b-col cols="12" md="8">
			<b-card v-if="campaignData" class="campaignDataOverflow">
				<div>
					<div class="d-flex align-items-center justify-content-between" id="mobileHeader">
						<div class="d-flex align-items-center">
							<div class="icon mr-1">
								<social-icons :socialType="campaignData.campaign_type" :size="'48'"></social-icons>
							</div>

							<div class="info">
								<h3>{{campaignData.campaign_name}}
								</h3>
								<campaign-detail-status :campaignData="campaignData"></campaign-detail-status>
							</div>
						</div>
						<div class="d-flex align-items-center" id="mobileButtons">
							<b-button v-if="campaignData.ad_id"
								:href="'https://ads.snapchat.com/'+campaignData.ad_account_id+'/ads/'+campaignData.ad_id+'?&ad_id='+campaignData.ad_id+''"
								target="_blank" variant="relief-warning" class="mr-1">Snapchat Ad</b-button>
							<b-button :href="fakeStoreLink+'/app/'+campaignData.uuid" target="_blank"
								variant="relief-secondary" class="mr-1">App Store</b-button>
							<b-button v-b-modal.video-detail variant="relief-dark">Preview Video</b-button>
							<b-modal id="video-detail" ok-only ok-title="Close" centered
								:title="campaignData.campaign_name">
								<div class="embed-responsive embed-responsive-16by9 mt-1">
									<video controls="controls">
										<source :src="apiUrl+campaignData.file_path">
									</video>
								</div>
							</b-modal>
						</div>
					</div>

				</div>
			</b-card>
			<b-card v-else-if="isLoading" class="mt-2">
				<div class="text-center my-2">
					<b-spinner class="d-block mx-auto mb-50"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</b-card>


			<b-card class="card-transaction" no-body v-if="!isLoading && campaignData && campaignData.logs.length>0">
				<b-card-header>
					<b-card-title>Snapchat Api Result</b-card-title>
				</b-card-header>
				<b-card-body>
					<div v-for="log in campaignData.logs" :key="log.id" class="transaction-item">
						<b-media no-body @click="showModalResponseDetail(log.type,log.details)" v-b-modal.result-detail>
							<b-media-aside>
								<b-avatar rounded size="42" :variant="log.success?'light-success':'light-danger'">
									<feather-icon size="16" :icon="log.success?'CheckIcon':'XIcon'" />
								</b-avatar>
							</b-media-aside>
							<b-media-body>
								<h6 class="transaction-title mb-0">
									{{ log.type }}
								</h6>
								<small>Show</small>
							</b-media-body>
						</b-media>
						<div class="font-weight-bolder" :class="log.success ? 'text-success':'text-danger'">
							{{ log.success ? 'OK':'FAILED' }}
						</div>
					</div>
				</b-card-body>
			</b-card>

		</b-col>

		<b-col cols="12" md="4">
			<b-card title="App Preview Demo" no-body>
				<div class="guru-browser" id="blanik_me-macwindow-1">
					<div class="guru-browser-bar"><a class="guru-browser-btn guru-browser-btn-1"></a>
						<div class="guru-browser-btn guru-browser-btn-2"></div><a
							class="guru-browser-btn guru-browser-btn-3"></a>
					</div>
					<div class="guru-browser-browser p-0" v-if="campaignData">
						<iframe :src="fakeStoreLink+'/review/'+campaignData.app_id" frameborder="0" id="iframeVal"
							scrolling="yes"></iframe>
					</div>
				</div>
			</b-card>
		</b-col>

		<b-modal id="result-detail" ok-only ok-title="Close" centered :title="this.modalShowTitle">
			<pre>{{this.modalShowDetail | pretty}}</pre>
		</b-modal>
	</b-row>
</template>

<script>
import {
	BCard,
	BCardText,
	BBadge,
	BImg,
	BAlert,
	BButton,
	BRow,
	BCol,
	BSpinner,
	BLink,
	BEmbed,
	BAvatar,
	BMedia,
	BMediaAside,
	BMediaBody,
	BCardBody,
	BCardTitle,
	BCardHeader,
	BModal,
	VBModal
} from 'bootstrap-vue'
import useCampaignApi from '@/composables/useCampaignApi'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import SocialIcons from '@/components/SocialIcons.vue'
import CampaignDetailAnalytics from '@/components/CampaignDetailAnalytics.vue'
import CampaignDetailStatus from '@/components/CampaignDetailStatus.vue'
import {
	ref
} from '@vue/composition-api'
export default {
	data: function () {
		return {
			apiUrl: process.env.VUE_APP_API_URL.replace('api/', ''),
			fakeStoreLink: process.env.VUE_APP_FAKE_STORE_LINK,
		}
	},
	components: {
		SocialIcons,
		BEmbed,
		BLink,
		BSpinner,
		BCard,
		BCardText,
		BBadge,
		BImg,
		BAlert,
		BButton,
		BRow,
		BCol,
		BAvatar,
		BMedia,
		BMediaAside,
		BMediaBody,
		BCardBody,
		BCardTitle,
		BCardHeader,
		BModal,
		CampaignDetailAnalytics,
		CampaignDetailStatus
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	filters: {
		pretty: function (value) {
			return JSON.stringify(JSON.parse(value), null, 2);
		}
	},
	mounted() {
		this.fetchCampaign(router.currentRoute.params.campaign)
	},
	methods: {
		showModalResponseDetail(title, type) {
			this.modalShowTitle = title + ' Detail'
			this.modalShowDetail = type
		}
	},
	setup() {
		const modalShowTitle = ref(null)
		const modalShowDetail = ref(null)
		const {
			campaignData,
			fetchCampaign,
			isLoading,
		} = useCampaignApi()

		return {
			campaignData,
			fetchCampaign,
			isLoading,
			modalShowTitle,
			modalShowDetail
		}
	},
}
</script>

<style>

.guru-browser {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.guru-browser-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  background: #edebed;
  border-radius: 5px 5px 0 0;
}
@media (max-width: 767px) {
  .guru-browser-bar {
    border-radius: 15px 15px 0 0;
  }
}
.guru-browser-btn {
  position: relative;
}
.guru-browser-btn-1 {
  left: 15px;
  background: #fc615d;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-1 {
    background: #BDBDBD;
    width: 36px;
    border-radius: 100px;
  }
}
.guru-browser-btn-2 {
  left: 32px;
  background: #fdbd41;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-2 {
    display: none;
  }
}
.guru-browser-btn-3 {
  left: 49px;
  background: #34c84a;
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  top: 14px;
}
@media (max-width: 767px) {
  .guru-browser-btn-3 {
    display: none;
  }
}
.guru-browser .guru-browser-browser {
  width: 100%;
  height: 60vh;
}
.guru-browser .guru-browser-browser #iframeVal {
  width: 100%;
  height: 100%;
  border-radius: 0 0 5px 5px;
}

#result-detail pre::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
#result-detail pre::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#result-detail pre::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
#result-detail pre{
	overflow: auto;
	max-height: 500px;
}
#result-detail pre{
	background:#fff;
}
#result-detail .modal-body{
	padding-bottom: 0px;
}
@media (max-width: 996px){
	#mobileHeader{
		display:block!important;
	}
	#mobileButtons{
		margin-top:10px;
		display: inline-block!important;

	}
	#mobileButtons .btn{
		margin-bottom:5px;
	}
}

</style>
