<template>
    <b-card no-body>
        <b-card-header>
            <b-card-title>Snapchat Analytics</b-card-title>
        </b-card-header>
        <b-card-body class="pl-0 pr-0 pb-0">
            <b-table :table-class="'build-list-table table dataTable no-footer dtr-column'" responsive
                :items="analytics" v-model="visibleRows">
                <template #cell(date)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.date}}</span>
                </template>

                <template #cell(hour_format)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.hour_format}}</span>
                </template>

                <template #cell(impressions)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.impressions}}</span>
                </template>

                <template #cell(spend)="data">
                    <span class="align-text-top text-capitalize">${{ data.item.spend.toFixed(2)}}</span>
                </template>

                <template #cell(swipe_up_percent)="data">
                    <span class="align-text-top text-capitalize">{{ (data.item.swipe_up_percent*100).toFixed(2)}}%</span>
                </template>

                <template #cell(swipes)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.swipes}}</span>
                </template>

                <template #cell(view_completion)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.view_completion}}</span>
                </template>

                <template #cell(conversion_sign_ups)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.conversion_sign_ups}}</span>
                </template>

                <template #cell(cost_per_conversion)="data">
                    <span class="align-text-top text-capitalize">${{ data.item.cost_per_conversion.toFixed(2)}}</span>
                </template>

                <template slot="bottom-row">
                    <td><b>Total</b></td>
                    <td>${{totalSpend.toFixed(2)}}</td>
                    <td>{{totalConSignUp}}</td>
                    <td>${{totalCostPerRate.toFixed(2)}}</td>
                    <td>{{totalImpressions}}</td>
                    <td>{{totalSwipeUpPerc.toFixed(2)}}%</td>
                    <td>{{totalSwipes}}</td>
                    <td>{{totalViewCompl}}</td>
                </template>
            </b-table>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCardSubTitle,
    BTabs,
    BTab,
    BTable,
} from 'bootstrap-vue'
export default {
    data: function () {
        return {
            analyticData: {},
            visibleRows:[],
        }
    },
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BCardBody,
        BCardTitle,
        BCardHeader,
        BCardSubTitle,
        BTabs,
        BTab,
        BTable,
    },
    props: {
        analytics: {}
    },
    computed: {
        totalImpressions() {
            return this.visibleRows.reduce((sum, item) => sum + item.impressions, 0);
        },
        totalSpend() {
            return this.visibleRows.reduce((sum, item) => sum + item.spend, 0);
        },
        totalConSignUp() {
            return this.visibleRows.reduce((sum, item) => sum + item.conversion_sign_ups, 0);
        },
        totalCostPerRate(){
            return this.totalSpend?this.totalSpend/this.totalConSignUp:0
        },
        totalSwipes(){
            return this.visibleRows.reduce((sum, item) => sum + item.swipes, 0);
        },
        totalViewCompl(){
            return this.visibleRows.reduce((sum, item) => sum + item.view_completion, 0);
        },
        totalSwipeUpPerc(){
             return this.totalImpressions?this.totalSwipes/this.totalImpressions*100:0
        }
    },

}
</script>

<style lang="scss">

</style>